<template>
  <div class="front_container">
    <!-- 头部背景部分 -->
    <div class="front_top">
      <div class="title">LD教员</div>
      <div class="advantage">简单易用、一端口了解日常的工作计划、更轻松的完成日常教学内容</div>
      <div class="details">
        无需下载安装，同时也能让学员在日常的工作生活中随时随地接入到学车的状态。以更简单易用的形式，确保每个年龄层的学员都能轻松上手，适合当前的不同年龄层的学员，同时实现学员与驾校的信息互通，让学员的每个进度与需求都能让驾校知晓。
      </div>
    </div>
    <!-- 中间介绍部分 -->
    <div class="front_main">
      <!-- 产品介绍 -->
      <div class="introduce">
        <div class="title">产品介绍</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="15">
            <div class="introduce_text">
              <div>
                <span
                  style="color:#1684fc">LD教员</span>,教练工作量化，模版化。让教练轻松掌握学员练车安排，考试成绩录入、财务报销、上下班打卡等众多内容。完全实现无纸化工作方式，让日常的教学工作通过一个手机就能轻松掌控.
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="pic">
              <img :src="introduce_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 核心功能 -->
      <div class="main_function">
        <div class="title">核心功能</div>
        <div class="language">FUNCTION</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 学员练车预约 -->
              <div class="function_item">
                <div class="title">
                  学员练车预约
                </div>
                <div class="details_text">将未来的工作内容，通过一个手机就能全面了解，更利于工作开展
                  Job Content •••</div>
              </div>
              <!--  学员练车考核 -->
              <div class="function_item">
                <div class="title">
                  学员练车考核
                </div>
                <div class="details_text">线上考核学员，让学员随时随地都能知晓自己的不足，以及教练的关怀
                  examinecheck •••</div>
              </div>
            </div>

          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="center" style="margin-top:30px">
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 接送安排 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  接送安排
                </div>
                <div class="details_text">让数据说话系统自动区分接送学员，教练只要跟进即可准确无误完成学员的接送
                  arrange •••</div>
              </div>
              <!-- 财务报销 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  财务报销
                </div>
                <div class="details_text">根据驾考行业的情况，将所有的财务报销通过线上就可以完成所有流程
                  finance •••</div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 产品价值 -->
      <div class="product_value">
        <div class="title">产品价值</div>
        <div class="language">VALUE</div>
        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value1_pic" alt="">
              </div>
              <div class="title">让教练，能轻松完成更多的教学内容</div>
              <div class="text">能最大提高教练员的工作效率</div>
            </div>

          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="text_list">
                <div>• 一个手机管理所有科目的练车、接送、考试</div>
                <div>• 符合驾校的考勤、财务需求，大大减少教练的路途时间成本</div>
                <div>• 能一部完成学员考试成绩录入，减少数据频繁传递</div>
                <div>• 能无缝实现各不同岗位工作待办事项数据的交互</div>
                <div>• 完善教练员的日常学习培训的需求，更能与时俱进</div>
              </div>
              <div class="value_pic">
                <img :src="value11_pic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 底部 -->
    <div class="front_footer">
      <BottomFooter></BottomFooter>
    </div>
  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  export default {
    name: 'WorkspaceJsonFrontpc',
    components: {
      BottomFooter
    },

    data() {
      return {
        introduce_pic: require('.././assets/introduce.png'),
        function_pic: require('.././assets/function.png'),
        value1_pic: require('.././assets/value1.png'),
        value11_pic: require('.././assets/value1-1.png'),
        value2_pic: require('.././assets/value2.png'),
        value22_pic: require('.././assets/value2-2.png'),
        value3_pic: require('.././assets/value3.png'),
        value33_pic: require('.././assets/value3-3.png'),

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .front_container {
    width: 100%;
    height: 100%;

    .front_top {
      width: 100%;
      height: 300px;
      background: url(".././assets/front_bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
      background-size: 100% 100%;
      color: #ffffff;
      box-sizing: border-box;
      padding: 50px 0;

      .title {
        font-size: 40px;
        margin-bottom: 20px;
      }

      .advantage {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .details {
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
        text-align: left;
      }
    }

    .front_main {
      box-sizing: border-box;
      padding: 30px 40px;
      border-bottom: 1px solid #ccc;

      .introduce,
      .main_function,
      .product_value {
        width: 100%;

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .language {
          color: rgba(233, 233, 233, 1);
          margin-bottom: 20px;
        }

        .introduce_text {
          width: 80%;
          height: 100%;
          text-align: left;
          line-height: 40px;
          display: flex;
          align-items: center;
        }

        .pic {
          width: 500px;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .function_text_box {
          text-align: left;
          width: 600px;

          .function_item {
            margin-bottom: 30px;

            .title {
              font-size: 18px;
              font-weight: normal;
              margin-bottom: 10px;
            }

            .details_text {
              line-height: 30px;
              color: rgba(145, 145, 145, 1);
            }
          }

        }

        .left {
          width: 100%;
          box-sizing: border-box;
          padding-left: 60px;
          text-align: left;

          .value_pic {
            width: 60px;
            height: 50px;
            margin-bottom: 20px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
          }

          .text {
            color: rgba(145, 145, 145, 1);
          }
        }

        .right {
          width: 600px;
          height: 180px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          position: relative;

          .text_list {
            text-align: left;
            margin-left: 40px;
          }

          .value_pic {
            width: 150px;
            height: 150px;
            position: absolute;
            right: -60px;
            bottom: -10px;
          }
        }


      }
    }

    .front_footer {
      height: 100px !important;
    }

  }

</style>
